// // src/pages/[...].js
// import React from "react";
// import Search from "../../templates/search";
// import { addTraillingSlash, generateFilters, parseResultsUrl } from "@lib/searchUtils";
// import PropertyDetailTemplateWrap from "@templates/property-detail-temaplte";
// import algoliasearch from "algoliasearch/lite";
// import SearchResults from "@templates/new-projects-search-template";

// export async function getServerData(context) {
//     const { params } = context; // Extract dynamic params from context
//     const path = "/new-projects/" + params["*"]; // Access the wildcard param
//     const locationObject = {
//         pathname: addTraillingSlash(path) || '', // Ensure it's a string or default to an empty string
//     };




//     if (path.startsWith("/new-projects")) {
//         const pageUrlData = parseResultsUrl(locationObject)
//         const filterQuery = generateFilters({ ...pageUrlData })

//         const API_URL = `${process.env.PROPERTY_API_URL}/api/search`;
//         const AUTH_TOKEN = process.env.PROPERTY_API_TOKEN;
       
//         const headers = {
//             "Accept-Encoding": "gzip, deflate",
//             "Content-Type": "application/json",
//             Authorization: AUTH_TOKEN,
//         };
//         // Fetch data for Search template
//         const sortQuery = (() => {
//           switch (pageUrlData?.sortVal) {
//             case "prod_new_developments_price_desc": return { filter_price: "desc" };
//             case "prod_new_developments_price_asc": return { filter_price: "asc" };
//             default: return {  "`rank`" : "asc", updated_at: "desc"   };
//           }
//         })();

//         const body = {
//           content_type: "off_plan",
//           algolia_data: true,
//           filters: filterQuery,
//           sort: sortQuery,
//           page: pageUrlData?.pageVal || 0,
//           limit: "10",
//           content: {
//             component:"offplan_copy",
//             area: pageUrlData?.areaVal ? pageUrlData?.areaVal : ["all"],
//             building_type : pageUrlData?.buildingVal ? pageUrlData?.buildingVal : "",
//             department: pageUrlData?.departmentVal
//           }
//         };

//         const res = await fetch(API_URL, {
//             method: "POST",
//             headers,
//             body: JSON.stringify(body),
//         });
//         const data = await res.json();
//         return { props: { data, template: "search" } };
//     }

//     return { props: { data: null, template: null } };
// }

// const DynamicPage = ({ serverData, location }) => {
// if (serverData.template === "search") {
//         return <SearchResults serverData={serverData?.data} location={location} />;
//     } else {
//         return <div>404 - Page Not Found</div>;
//     }
// };

// export default DynamicPage;
// src/templates/search.js
import React from "react";

const Search = ({ data }) => {
  return (
    <div>
      <h1>New projets Search Page</h1>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  );
};

export default Search;
